@import "./../../../abstracts/variables";
@import "./../../../abstracts/mixins";

.toTop {
    width: 40px;
    height: 40px;
    background-color: color(blue3);
    border-radius: 100px;
    position: fixed;
    bottom: 20px;
    right: 15px;
    @include flexPosition(center, center);
    transition: bottom 0.2s;
    z-index: 99;
    cursor: pointer;

    &:hover {
        bottom: 23px;
    }

    &Arrow {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid color(white);
        margin-bottom: 2px;
    }
}
