@import "./../../../../abstracts/variables";
@import "./../../../../abstracts/mixins";

.upcoming {
    &-event-hover {
        .Crop:hover {
            height: 23vw;
            max-height: 33rem;
            margin-top: -1.5rem;
        }
        @include response(lg) {
            .Crop:hover {
                height: 26vw;
                max-height: 26vw;
                margin-top: -3rem;
            }
        }
        @include response(lg/mg) {
            .Crop:hover {
                height: 30vw;
                max-height: 30vw;
            }
        }
        @include response(md) {
            .Crop:hover {
                height: calc(38vw - 0rem);
                max-height: calc(38vw - 0rem);
                margin-top: -3rem;
            }
        }

        @include response(sm) {
            .Crop:hover {
                height: 45vw;
                max-height: 45vw;

                margin-top: -1.5rem;
            }
        }
    }

    &-event {
        text-decoration: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        width: 26vw;
        max-width: 31rem;
        outline: none;

        @include response(lg/md) {
            width: 30vw;
        }
        @include response(sm) {
            width: 45vw;
        }

        &-img {
            width: 23vw;
            height: 23vw;
            max-height: 33rem;
            margin-bottom: 2rem;
            @include flexPosition(center, center);

            .Crop {
                height: calc(23vw - 4rem);
                width: calc(23vw - 4rem);
                max-width: 30rem;
                max-height: 30rem;
                @include flexPosition(center, center);

                -webkit-clip-path: circle(50% at 50% 50%);
                clip-path: circle(50% at 50% 50%);
                -webkit-transition: all 0.5s;
                -moz-transition: all 0.5s;
                -ms-transition: all 0.5s;
                transition: all 0.5s;

                &-src {
                    display: block;
                    height: 23vw;
                    max-height: 33rem;
                }
            }
            @include response(lg) {
                width: 26vw;
                max-width: 26vw;
                height: auto;

                .Crop {
                    height: calc(26vw - 3rem);
                    max-height: 26vw;

                    &-src {
                        display: block;
                        height: 26vw;
                        max-height: 26vw;
                    }
                }
            }
            @include response(lg/mg) {
                width: 30vw;
                max-width: 30vw;
                height: auto;

                .Crop {
                    height: calc(30vw - 3rem);
                    max-height: 30vw;

                    &-src {
                        display: block;
                        height: 30vw;
                        max-height: 30vw;
                    }
                }
            }
            @include response(md) {
                width: 38vw;
                max-width: 38vw;
                height: auto;

                .Crop {
                    height: calc(38vw - 3rem);
                    max-height: 38vw;
                    width: calc(38vw - 3rem);

                    &-src {
                        display: block;
                        height: 38vw;
                        max-height: 38vw;
                    }
                }
            }

            @include response(sm) {
                width: 45vw;
                max-width: 45vw;
                height: auto;
                padding: 0;
                margin: 0;

                .Crop {
                    height: calc(45vw - 1rem);
                    max-height: 45vw;

                    &-src {
                        display: block;
                        height: 45vw;
                        max-height: 45vw;
                    }
                }
            }
        }

        &-name {
            font-family: font(roboto);
            font-size: size(lg) - 0.5rem;
            text-align: center;
            font-weight: 300;
            letter-spacing: 0.2px;
            color: color(white);
            display: inline-block;
            width: 100%;

            @include response(xl) {
                font-size: size(lg) - 0.9rem;
            }

            @include response(lg) {
                font-size: size(md) + 0.5rem;
            }

            @include response(lg/md) {
                font-size: size(md);
            }

            @include response(md) {
                font-size: size(md) + 0.5rem;
                margin-top: 0.5rem;
            }

            @include response(sm) {
                font-size: size(sm) + 0.3rem;
            }
        }

        &-date {
            font-family: font(source);
            font-size: size(sm) + 0.2rem;
            text-align: center;
            margin-top: 1rem;
            color: color(white);
            text-decoration: none !important;
        }

        @include response(md) {
            &:nth-child(3) {
                display: none;
            }
            &-date {
                font-size: size(sm);
            }
        }
    }
}
