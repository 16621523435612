@import "./../../../abstracts/variables";
@import "./../../../abstracts/mixins";

.header {
    @include flexPosition($alIte: flex-start);
    width: 100vw;
    padding: 0rem 2rem;
    position: fixed;
    top: 0;
    z-index: 100;
    transition: top 0.5s;

    .logo {
        height: calc(50vw / 6);
        max-height: 110px;
        min-height: 50px;
        margin-top: 2rem;
        &Img {
            height: 100%;
        }
    }

    .logoShrink {
        height: 5rem !important;
        width: 5rem !important;
        margin-top: 0;
    }

    @include response(md) {
        padding: 0 2rem;
        .logo {
            margin-top: 1rem;
        }
    }
    @include response(sm) {
        padding: 0 1rem;
    }
}
.headerHidden {
    top: -7rem;
}

.teamPage {
    .navListItem {
        color: color(blue1);
    }
}

.scrolled {
    align-items: center;
    background-color: rgba(4, 133, 186, 0.8);
    box-shadow: 2px -5px 8px rgba(0, 0, 0, 0.14), 0 -1px 10px -2px rgba(0, 0, 0, 0.12),
        0 -1px 15px 0 rgba(0, 0, 0, 0.2);

    .navListItem {
        color: color(white);
    }
}

// Navigation on top right corner
.nav {
    &List {
        @include flexPosition(space-around);
        font-size: size(xs);
        font-weight: 600;
        font-family: font(roboto);

        &Item {
            list-style: none;
            padding: 2rem 1.25rem;
            color: color(white);

            &:hover,
            &:focus {
                color: color(blue5);
                background-color: transparent;
                outline: none;
            }

            &Active {
                color: color(orange1);
            }

            @include response(lg/md) {
                padding: 2rem 0.9rem;
            }
        }

        a {
            font-size: size(xs) !important;
            transition: all 0.3s;
            list-style: none;
            text-align: center;
            text-decoration: none;
        }
    }
}

.hamburgerMenu {
    width: 40px;
    height: 40px;
    margin-top: 1rem;
    z-index: 300;

    transform: rotateZ(90deg);
    transform-origin: center center;

    @include response(lg) {
        transform: rotateZ(0deg);
    }
    @include response(sm) {
        right: 3rem;
    }

    .menu {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: space-around;
        cursor: pointer;
        transition: transform 0.5s;

        &Line {
            width: 100%;
            height: 4px;
            background-color: color(orange1);
            transition: all 0.5s 0.5s;
            transform-origin: right;
        }
    }
}

@include response(md-up) {
    .checkbox,
    .hamburgerMenu {
        display: none;
    }
}

@include response(md) {
    .slideoutNav {
        padding-left: 20px;
        width: 750px;
        height: 60px;
        background-color: color(white);
        position: fixed;
        right: -750px;
        top: 0;
        z-index: -1;
        transition: right 0.8s cubic-bezier(1, 0, 0, 1);
        @include flexPosition;
        border-bottom: solid 1px #ccc;
        border-left: solid 1px #ccc;

        @include response(lg) {
            width: 60vw;
            right: -60vw;
            height: 100vh;
            @include flexPosition(center, center);

            .navList {
                flex-direction: column;
                width: 100%;

                a {
                    padding: 1rem;

                    li {
                        font-size: size(lg);
                        color: color(blue1);
                    }
                }
            }
        }

        @include response(lg) {
            .navList a li {
                font-size: size(md);
            }
        }
    }

    .checkbox:checked ~ .slideoutNav {
        right: 0;
    }
    .checkbox:checked ~ .hamburgerMenu .menu {
        transform: rotateZ(90deg);
    }
    .checkbox:checked ~ .hamburgerMenu .menuLine1 {
        transform: rotateZ(-42deg);
    }
    .checkbox:checked ~ .hamburgerMenu .menuLine2 {
        opacity: 0;
    }
    .checkbox:checked ~ .hamburgerMenu .menuLine3 {
        transform: rotateZ(42deg);
    }
}
