@import "./../../Home/FooterHome/footerHome.module.scss";

.smallFooter {
    padding: 1rem 0;
    z-index: 100;
    margin: 0;
    width: 100% !important;

    p {
        margin: 1rem 60px 0;
        font-weight: normal;
        font-size: size(xs);
    }
}
