@import "./../../../../abstracts/variables";
@import "./../../../../abstracts/mixins";

.sponsors {
    @include flexPosition;

    &Container {
        @include flexPosition;
        flex-direction: column;
        transition: all 0.4s;
        padding: 1rem 4rem 0;
        text-decoration: none;
        margin-bottom: 2rem;

        &:hover {
            padding-top: 0;
        }

        &ImgDiv {
            height: 10rem;
            width: 100%;
            @include flexPosition(center, center);
            outline: none;
        }

        &Img {
            display: block;
            max-width: 15vw;
            max-height: 10rem;
            width: auto;
            height: auto;
        }

        @include response(lg/md) {
            margin-top: 0;
            &ImgDiv {
                height: 10rem;
            }
        }

        @include response(md) {
            padding: 1rem 0 0;

            &ImgDiv {
                height: 10rem;
            }

            &Img {
                max-width: 30vw;
            }
        }
    }
}
