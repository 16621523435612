@import "./../../../abstracts/variables";
@import "./../../../abstracts/mixins";
@import "./../Events/events.module.scss";

.sponsors {
    width: 100vw;
    margin: 0 auto;
    max-width: 3000px;
    padding: 4rem 0;
    background: color(white);

    @include response(md) {
        width: 120vw;
        margin-left: -10vw;
        padding: 3rem 0 2rem;
    }

    &Div {
        @extend .eventsDiv;
        overflow: hidden;
        background-color: color(white);

        &Heading {
            @include title;
            font-size: size(xl);
        }

        @include response(sm) {
            padding: 6rem 0 5rem;
        }
    }
}

.slick-slide div {
    margin: 20px;
}

.spread {
    justify-content: center;
    padding-bottom: 20px;
    flex-wrap: wrap;

    > a {
        padding: 20px;
        flex: 1 1 100px;
        margin: 10px;

        @include response(sm) {
            padding: 20px 10px;
        }
    }
}