@import "./../../../abstracts/variables";
@import "./../../../abstracts/mixins";

.card {
    @include flexPosition;
    flex-direction: column;
    margin: 0 4vw 3rem;
    z-index: 1;
    width: 21rem;
    color: color(font);

    &Crop {
        height: 18rem;
        width: 18rem;
        -webkit-clip-path: circle(50% at 50% 50%);
        clip-path: circle(50% at 50% 50%);
        @include flexPosition(center, center);

        &Img {
            width: 100%;
        }
    }

    &Title {
        font-family: font(roboto);
        font-weight: bold;
        font-size: 2rem;
        margin: 1.2rem 0 0.5rem;
        text-align: center;
        letter-spacing: 0.02rem;
    }
    &Name {
        font-family: font(source);
        font-size: 1.8rem;
        text-align: center;
    }

    &Btn {
        display: flex;
        justify-content: space-around;
        padding: 1rem;
        box-sizing: border-box;

        &Email,
        &Linkedin {
            text-decoration: none;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            @include flexPosition(center, center);
            background-color: color(blue1);
            margin: 0 1.5rem;
            transition: all 0.3s;

            &Icon {
                width: 20px;
                z-index: 1;
            }

            &:hover {
                background-color: darken(color(blue1), 10%);
            }
        }
        &Email {
            &Icon {
                width: 24px;
            }
        }
    }

    @include response(lg/md) {
        margin: 0 3vw 3rem;
        width: 18rem;
        &Crop {
            height: 16rem;
            width: 16rem;
        }
    }

    @include response(md) {
        width: 17rem 1rem;
        &Crop {
            height: 14rem;
            width: 14rem;
        }
    }

    @include response(sm) {
        width: 16rem;
        margin: 0 2vw 1rem;

        &Title {
            font-size: 1.7rem;
        }
        &Name {
            font-size: 1.5rem;
        }
    }
}
