@import "./../../../abstracts/variables";
@import "./../../../abstracts/mixins";

// Images of the skyline

.skyline-outline,
.skyline-solid {
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-70%, -50%);
    height: 85vh;
    max-height: 100rem;
    overflow-x: hidden;
    z-index: -10;

    @include response(lg/md) {
        left: 40vw;
    }
}
// End of images of the skyline

// Blue trapezoid that crops skyline-solid
.blue-background {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background-color: color(blue1);
    width: 100vw;
    height: 100vh;
    max-height: 150rem;
    overflow: hidden;
    position: relative;
    z-index: -1;
    -webkit-clip-path: polygon(50% 0%, 100% 0, 100% 100%, 0 100%, 0 86%);
    clip-path: polygon(50% 0%, 100% 0, 100% 100%, 0 100%, 0 86%);

    @include response(lg/md) {
        -webkit-clip-path: polygon(40% 0%, 100% 0, 100% 100%, 0 100%, 0 86%);
        clip-path: polygon(40% 0%, 100% 0, 100% 100%, 0 100%, 0 86%);
    }

    display: inline-block;
    white-space: nowrap;

    @include response(lg) {
        max-height: 120rem;
    }
}

// Div of skyline-outline
.no-background {
    width: 100vw;
    height: 100vh;
    max-height: 150rem;
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    white-space: nowrap;
    z-index: -10;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

// Text of "IEEE UofT Student Branch
.heading {
    width: 50%;
    padding: 10rem;
    padding-left: 0;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    position: relative;
    right: -46vw;

    &-title,
    &-blue-title {
        text-align: right;
        font-family: font(roboto);
        font-weight: bold;
        font-size: 20rem;
        color: color(white);
    }

    &-subtitle,
    &-blue-subtitle {
        text-align: right;
        font-family: font(source);
        font-weight: bold;
        font-size: size(lg);
        color: rgba(255, 255, 255, 0.9);
    }

    &-blue {
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        padding: 10rem;
        z-index: -4;

        &-title,
        &-subtitle {
            color: color(blue1);
        }
    }

    @include response(xl) {
        &-title,
        &-blue-title {
            font-size: 18rem;
        }
        &-subtitle,
        &-blue-subtitle {
            font-size: size(lg) - 1rem;
        }
    }
    @include response(lg) {
        padding: 8rem;
        padding-left: 0;
        &-blue {
            padding: 8rem;
        }
        transform: translate(0%, 5%);

        &-title,
        &-blue-title {
            font-size: 20rem;
        }
        &-subtitle,
        &-blue-subtitle {
            font-size: size(lg)-0.5rem;
        }
    }
    @include response(lg/md) {
        &-blue {
            padding: 6rem;
        }
        &-title,
        &-blue-title {
            font-size: 17rem;
        }
        &-subtitle,
        &-blue-subtitle {
            font-size: size(md);
        }
    }
    @include response(md) {
        padding: 5rem;
        padding-left: 0;
        &-blue {
            padding: 5rem;
            padding-left: 0;
        }

        &-title,
        &-blue-title {
            font-size: 14rem;
        }
        &-subtitle,
        &-blue-subtitle {
            font-size: size(md);
        }
    }
    @include response(sm) {
        padding: 3rem;
        top: 15vw;
        &-blue {
            padding: 3rem;
        }
        &-subtitle,
        &-blue-subtitle {
            font-size: size(md) !important;
        }
    }
}

// Change the font-color of the nav from white to blue when the header color changes to white
.change-color {
    color: color(blue1) !important;
}

// Change header color to white
.change-color2 {
    background-color: #fff;
    z-index: 100;
    color: color(blue1);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    @include flexPosition(space-between, center);
}
