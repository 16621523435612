@import "./../../abstracts/variables";
@import "./../../abstracts/mixins";

.card {
    @include flexPosition;
    flex-direction: column;
    margin: 0 5vw;
    z-index: 1;

    &-crop {
        height: 21rem;
        width: 21rem;
        -webkit-clip-path: circle(50% at 50% 50%);
        clip-path: circle(50% at 50% 50%);
        @include flexPosition(center, center);

        &-img {
            width: 100%;
        }
    }

    &-title {
        font-family: font(roboto);
        font-weight: bold;
        font-size: 2.2rem;
        margin-top: 1.2rem;
        text-align: center;
    }
    &-name {
        font-family: font(source);
        font-size: 1.8rem;
        text-align: center;
    }

    &-btn {
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        box-sizing: border-box;

        &-email,
        &-linkedin {
            text-decoration: none;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            @include flexPosition(center, center);
            background-color: #00639c;
            margin: 0 1.5rem;
            transition: all 0.3s;

            &-icon {
                width: 20px;
                z-index: 1;
            }

            &:hover {
                background-color: darken(color(blue1), 10%);
            }
        }
        &-email {
            &-icon {
                width: 24px;
            }
        }
    }
}

@mixin flexPosition($jusCon: space-between, $alIte: center) {
    display: flex;
    justify-content: $jusCon;
    align-items: $alIte;
}

.skylineImgs {
    width: 100vw;
    overflow-x: hidden;

    @include flexPosition(space-between, flex-end);

    &Crop {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        margin-top: -25vw;
        overflow-x: hidden;
        z-index: -100;
        width: 100%;
        position: relative;
    }
    &Lr {
        width: 40vw;
        max-width: 55rem;
        overflow-x: hidden;
    }

    @include response(lg/md) {
        width: 120vw;
        margin-left: -10vw;

        &Lr {
            width: 40vw;
        }
    }
    @include response(md) {
        &Crop {
            margin-top: -35vw;
        }

        &Lr {
            width: 50vw;
        }
    }

    @include response(sm) {
        &Lr {
            width: 55vw;
        }
    }
}

.row {
    @include flexPosition(center, flex-start);
    width: 100%;
    flex-wrap: wrap;
}

.team {
    height: 100vh;
    @include flexPosition;
    flex-direction: column;

    &Title {
        font-family: font(roboto);
        font-weight: bold;
        font-size: 5rem;
        text-align: center;
        color: color(blue1);
        margin: 5rem 0 4rem;
    }

    &Container {
        width: 100vw;
        flex-direction: column;
        @include flexPosition;
    }
}

.teamNav {
    width: 100vw;
    @include flexPosition(center, center);
    flex-direction: column;
    margin: 4rem 0;
    z-index: 101;

    &List {
        @include flexPosition;

        &Item {
            font-family: font(roboto);
            font-weight: bold;
            font-size: 2rem;
            text-align: center;
            color: color(blue1);
            margin: 0 2rem;
            padding-bottom: 5px;
            list-style: none;
            line-height: 120%;
            cursor: pointer;
            white-space: nowrap;
            transition: border-bottom-style 0.4s;

            &:hover,
            &Active {
                border-bottom: 2px solid color(blue1);
                padding-bottom: 3px;
            }
        }

        @include response(lg/md) {
            width: 100%;
            overflow-x: scroll;
            align-self: flex-end;
            padding-bottom: 2rem;
        }

        @include response(md) {
            &-item {
                font-size: 1.8rem;
                padding: 1.5rem;
            }
        }
    }
    @include response(lg/md) {
        margin-bottom: 2rem;
    }
}

.selectYear {
    width: 100%;
    margin-top: 9vw;
    @include flexPosition(center, center);
    flex-direction: row-reverse;
    z-index: 101;

    &Div {
        display: inline-block;
        font-family: font(roboto);
        font-weight: bold;
        font-size: size(md) - 0.5rem;
        color: color(blue1);

        width: 280px;
        height: auto;

        border: none;
        outline: none;
        border-bottom: 3px solid color(blue1);
        border-radius: 0;
        cursor: pointer;

        padding: 12px 45px 10px 25px;

        background: none;
        -webkit-appearance: none;
    }

    @include response(md) {
        margin-top: 8rem;
    }
}

.spacer1 {
    height: 5vh;
}

.spacer2 {
    height: 25vh;
    font-size: 5vh;
    text-align: center;
}

.triangleDown {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid color(blue1);
    margin-left: -36px;
    z-index: -1;
}

.toggles {
    margin-right: 5vw;
    margin-bottom: -3rem;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: flex-end;

    .baby {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: flex-end;
        margin-left: 25px;

        &Emoji {
            height: 25px;
            margin-left: 10px;
            margin-bottom: 5px;
        }

        Label {
            cursor: pointer;
        }
    }

    @include response(lg/md) {
        margin-top: 2rem;
        margin-bottom: -2rem;
        justify-content: center;
        margin-right: 0;
    }
}
