@import "./../../../abstracts/variables";
@import "./../../../abstracts/mixins";

.compactBtn {
    font-family: font(source);
    color: color(blue1);
    font-weight: bold;
    margin-right: 5rem;
    font-size: size(sm);
    cursor: pointer;
    align-self: flex-end;

    &:hover {
        text-decoration: underline;
    }

    @include response(sm) {
        margin-right: 2rem;
    }
}

.eventsDiv {
    width: 100%;
    @include flexPosition;
    flex-direction: column;
    align-items: center;
    padding: 8rem 0 0;
    margin-top: -3rem;
    background-color: color(white);

    &Heading {
        @include title;
        font-size: size(xl);
    }

    .events {
        width: 100%;
        align-self: center;
        padding: 2rem 0;
        background: color(white);
        overflow-x: hidden;
        outline: none;
    }

    @include response(lg/md) {
        padding: 5rem 0;

        .events {
            padding: 2rem 0 0.5rem;
        }
    }

    @include response(sm) {
        padding: 4rem 0;
    }
}

.spread {
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 0;
}

.slick-current {
    margin-top: 30px !important;
}
