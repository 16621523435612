@import "./../../../abstracts/variables";
@import "./../../../abstracts/mixins";

.upcomingDiv {
    background-color: color(blue2);
    width: 100vw;
    @include flexPosition;
    flex-direction: column;
    padding: 8rem 0;
    margin-top: -3rem;
    z-index: -1;

    a {
        text-decoration: none !important;
    }

    &Heading {
        @include title(white);
        font-size: size(xl);
    }

    .upcoming {
        @include flexPosition(space-between, flex-start);
        width: 90%;
        max-width: 1200px;
        margin-top: 5rem;

        @include response(md) {
            width: 80%;
            margin-top: 6rem;
        }

        @include response(sm) {
            width: 90%;
            margin-top: 2rem;
        }
    }
}

.two {
    justify-content: center !important;

    a {
        margin-left: 5vw;
        margin-right: 5vw;
    }
}

.none {
    display: none !important;
}
