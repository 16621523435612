@import "./../../../../abstracts/variables";
@import "./../../../../abstracts/mixins";

$widthThird: 33.33vw;
$widthFifth: 20vw;

.darkGradient {
    width: $widthThird;
    height: 100%;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(47, 47, 47, 0) 33.19%,
        rgba(0, 0, 0, 0.25) 53.83%,
        rgba(0, 0, 0, 0.346916) 66.3%,
        rgba(0, 0, 0, 0.46) 81.5%
    );
    position: absolute;

    @include response(xs) {
        background: rgba(0, 0, 0, 0.32);
    }
}

.event {
    width: $widthThird;
    height: $widthThird;
    display: flex !important;
    @include flexPosition(center, center);
    flex-direction: column;
    position: relative;

    &Hover:hover {
        .eventCropImg {
            height: calc(100% + 40px);
            width: auto;
            margin: -10px 0 0 -10px !important;
        }
    }

    &Crop {
        -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);

        height: 100%;
        width: 100%;

        &Img {
            height: calc(100% + 20px);
            width: auto;
            margin: 0 0 0 0 !important;

            -webkit-transition: all 0.7s;
            -moz-transition: all 0.7s;
            -ms-transition: all 0.7s;
            transition: all 0.7s;
        }
    }

    &Text {
        max-width: $widthThird;
        padding: 0 20px;
        position: absolute;
        bottom: 3rem;
        transition: bottom 0.6s;

        &Name {
            font-family: font(roboto);
            font-size: 3.5rem;
            text-align: center;
            font-weight: normal;
            color: color(white);
            display: inline-block;
            width: 100%;
            margin: 0;
            text-shadow: 0 3px 8px rgba(0, 0, 0, 0.89);
            letter-spacing: 0.2px;
        }

        &Date {
            font-family: font(source);
            font-size: size(sm);
            text-align: center;
            margin-top: 0.5rem;
            color: color(white);
            text-shadow: 0 3px 6px rgba(0, 0, 0, 0.89);
        }

        @include response(xl) {
            &Name {
                font-size: size(md) - 0.5rem;
            }
        }
        @include response(lg/md) {
            bottom: 1rem;
            padding: 0 5px;

            &Name {
                font-size: size(sm) + 0.4rem;
            }
        }
        @include response(md) {
            &Name {
                font-size: size(sm);
            }
            &Date {
                font-size: size(sm)-0.5rem;
            }
        }
    }
}

@include response(sm-up) {
    .spread {
        width: $widthFifth !important;
        height: $widthFifth !important;

        .eventText {
            max-width: $widthFifth;
            bottom: 1rem;
            padding: 0 5px;
        }
    }

    .spread .darkGradient {
        width: $widthFifth;
    }
}
