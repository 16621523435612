@import "./../../../abstracts/variables";
@import "./../../../abstracts/mixins";

.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: color(blue6);
    margin-top: -1rem;

    &-heading {
        @include title(blue1);
        margin: 6rem 5px 3rem;
        font-size: size(lg);
        @include response(lg/md) {
            font-size: size(lg)-0.5rem;
        }
    }

    &-text {
        width: 80%;
        max-width: 800px;
        font-size: size(xs);
        line-height: 2.5rem;
        text-align: center;
        margin-bottom: 2rem;
    }

    &-btn-scroll {
        width: 80%;
        max-width: 500px;

        &-container {
            width: 100%;
            padding-bottom: 5rem;
            @include flexPosition($jusCon: center);

            &-button {
                cursor: pointer;
                width: 25rem;
                margin: 0 2rem;
                border: 2px solid color(blue1);
                color: color(blue1);
                background-color: transparent;
                font-family: font(roboto);
                font-weight: bold;
                font-size: size(md) - 0.5rem;
                text-align: center;
                padding: 1.5rem;
                margin-bottom: 15px;
                transition: all 0.5s;

                &:hover {
                    color: color(blue6);
                    border: 2px solid color(blue1);
                    background-color: color(blue1);
                }

                @include response(lg) {
                    font-size: size(md) - 0.7rem;
                    width: 20rem;
                }

                @include response(md) {
                    font-size: size(md) - 0.7rem;
                    width: 16rem;
                    margin: 0 1rem;
                }
            }
        }
        @include response(lg) {
            margin-top: 2rem;
        }
    }

    @include response(md) {
        &-text,
        &-btn-scroll {
            width: 100%;
            padding: 0 2rem;
        }
    }
}
